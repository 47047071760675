import * as React from "react";
import "../styles/globals.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "swiper/css";
import "react-circular-progressbar/dist/styles.css";
import Head from "next/head";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import NextNProgress from "nextjs-progressbar";
import Script from "next/script";

export type NextPageWithLayout<T = {}> = NextPage<T> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<T> = AppProps & {
  Component: NextPageWithLayout<T>;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout<any>) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return getLayout(
    <>
      <NextNProgress color="#006063" height={4} />
      <Script id="freshdesk">
        {`
	window.fwSettings={
	'widget_id':150000000431
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
	`}
      </Script>
      <Script
        type="text/javascript"
        src="https://widget.freshworks.com/widgets/150000000431.js"
        async
        defer
      ></Script>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {process.env.NODE_ENV !== "development" && (
          <>
            <link
              rel="preload"
              as="font"
              href="../public/assets/fonts/sf-pro-text/sf-pro-text-bold.ttf"
              type="font/ttf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href="../public/assets/fonts/sf-pro-text/sf-pro-text-heavy.ttf"
              type="font/ttf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href="../public/assets/fonts/sf-pro-text/sf-pro-text-light.ttf"
              type="font/ttf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href="../public/assets/fonts/sf-pro-text/sf-pro-text-medium.ttf"
              type="font/ttf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href="../public/assets/fonts/sf-pro-text/sf-pro-text-regular.ttf"
              type="font/ttf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href="../public/assets/fonts/sf-pro-text/sf-pro-text-semibold.ttf"
              type="font/ttf"
              crossOrigin="anonymous"
            />
          </>
        )}
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
